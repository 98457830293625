import { useDispatch } from "react-redux";
import useModal from "../../hooks/useModal";
import { setAdminAuthenticated } from "../../redux/userSlice";
import ConfirmationModal from "../Modals/ConfirmationModal";

const LogoutButton = ({ styled, adminLogout }) => {
  const dispatch = useDispatch();
  const { isShowing, toggle } = useModal();

  const handleLogout = async (event) => {
    if (!event) return;
    dispatch(setAdminAuthenticated(false));
  };

  return (
    <>
      <button
        className={`font-medium ${
          styled
            ? "text-white bg-red-500 hover:bg-red-600 text-white rounded-full px-7 py-2"
            : "text-red-700"
        }`}
        type="button"
        onClick={() => toggle()}
      >
        Logout
      </button>
      <ConfirmationModal
        title={"Are you sure you want to logout?"}
        isOpen={isShowing}
        onClose={toggle}
        onConfirm={handleLogout}
      ></ConfirmationModal>
    </>
  );
};

export default LogoutButton;
