import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import MoonLoader from "./components/MoonLoader";
import PageLayout from "./components/PageLayout";
import ScrollToTop from "./components/ScrollToTop";

const LazyAdmin = React.lazy(() => import("./pages/Admin"));
const LazyIntern = React.lazy(() => import("./pages/Admin/Intern"));
const LazyPayout = React.lazy(() => import("./pages/Admin/PayoutManagement"));

const App = () => {
  return (
    <ScrollToTop>
      <Suspense fallback={<MoonLoader />}>
        <Toaster />
        <PageLayout>
          <Routes>
            <Route path="/" element={<LazyAdmin />} />
            <Route path="/payout" element={<LazyPayout />} />
            <Route path="/intern/:id" element={<LazyIntern />} />
          </Routes>
        </PageLayout>
      </Suspense>
    </ScrollToTop>
  );
};

export default App;
