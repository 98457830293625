import { Modal } from "react-responsive-modal";

const ConfirmationModal = ({ title, isOpen, onClose, onConfirm }) => {
  return (
    <Modal open={isOpen} onClose={onClose} center>
      <div className="py-8 w-full flex items-center flex-col justify-start rounded-md">
        <h1 className="text-lg md:text-2xl font-medium text-center">{title}</h1>
        <div className="flex gap-4 mt-6">
          <button
            onClick={onConfirm}
            className="bg-red-500 rounded-md text-white px-6 py-3 text-md"
          >
            Yes
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 rounded-md text-gray-700 px-6 py-3 text-md"
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
