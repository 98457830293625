import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    adminAuthenticated: false,
    adminType: "",
    email: "",
  },
  reducers: {
    setAdminAuthenticated: (state, action) => {
      state.adminAuthenticated = action.payload.authenticated;
    },
    setAdminType: (state, action) => {
      state.adminType = action.payload.adminType;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
  },
});

export const {
  setUser,
  setEmail,
  setLogout,
  setAdminAuthenticated,
  setAdminType,
} = userSlice.actions;

export const selectPremiumAccess = (state) => state.user.hasPremiumAccess;
export const selectUser = (state) => state.user;
export const selectAdminAuthenticated = (state) =>
  state.user.adminAuthenticated;
export const selectAdminType = (state) => state.user.adminType;
export const selectEmail = (state) => state.user.email;
export default userSlice.reducer;
