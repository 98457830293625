import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAdminAuthenticated } from "../../redux/userSlice";
import LogoutButton from "../LogoutButton/LogoutButton";

const Navbar = () => {
  const adminAuthenticated = useSelector(selectAdminAuthenticated);

  return (
    <div className="w-full relative h-fit py-1 md:h-[80px] z-40 border-b border-[#BDBDBD]">
      <div className="flex-wrap m-auto w-full h-full flex justify-center vsm:justify-between items-center pl-3 md:pl-6">
        <Link to="/" className="">
          <img
            src="/logo.png"
            alt="logo"
            className="h-[45px] md:h-[50px] cursor-pointer z-40"
          />
        </Link>
        {adminAuthenticated && (
          <div className="px-7 flex items-center justify-center">
            <LogoutButton styled={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
